.customers {
  &.section {
    background-color: var(--color--green);
  }

  .hero & {
    width: 100%;
    margin-top: auto;
  }
}
.customers__title {
  .hero & {
    font-size: 16px;
    margin-top: 60px;
    margin-bottom: 16px;
  }
}
.customers__list {
  section.customers & {
    @extend %cardSet;
  }
}
.customers__item {
  @extend %flex-center-ja;

  section.customers & {
    position: relative;
    @extend %cardSet__item;
    flex-grow: 0;
    flex-shrink: 0;
    height: 80px;
    padding: 20px;
    fill: currentColor;

    color: var(--color--black);

    background-color: #fff;
    background-color: var(--color--white);
    // border: 8px solid #fff;
    
    box-shadow: 0 4px 24px rgba(0, 0, 0, .1);
    box-shadow: var(--box-shadow);

    transition: all 300ms linear;

    &:hover {
      transform: scale(1.05);
    }

    --items: 2;
    @include breakpoint-min($medium) {
      --items: 4;
    }
    @include breakpoint-min($large) {
      --items: 5;
    }
  }

  .customers__swiper & {
    height: 40px;
    padding: 10px 20px;
  }
}

.customer__icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  // background-color: var(--color--white);
}

.customers__swiper {
  background-color: var(--color--white);
}

.swiper-free-mode > .swiper-wrapper {
  transition-timing-function: linear !important;
}