.btn {
  @extend %flex-center-ja;
  min-width: 80px;
  height: 40px;

  color: inherit;
  background-color: inherit;

  @extend %border-radius;
  @extend %cursor;

  transition: all 300ms linear;
  
  &:hover,
  &:focus {
    transform: scale(1.02);
  }
}

.btn--green {
  color: var(--color--white);
  background-color: var(--color--green);
}
.btn--black {
  color: var(--color--white);
  background-color: var(--color--black);
}