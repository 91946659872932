.social-media__title {
  margin-bottom: 8px;
}

.social-media__list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.social-media__link {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 8px;
  height: 40px;
  max-width: 160px;
  margin-bottom: 4px;
  padding: 0 8px;

  transition: all 300ms linear;
}

.social-media__link:focus-visible {outline: none;}
.social-media__link:hover, .social-media__link:focus {color: var(--color--white);}
.social-media__link--linkedin:hover, .social-media__link--linkedin:focus {background: #0a66c2;};
.social-media__link--instagram:hover, .social-media__link--instagram:focus {
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
};

.wrap {
   background-color: var(--color--white);
}

.social-media__icon {
  flex: 0 0 20px;
  height: 20px;
  width: 20px;
}