@import './reset';
@import './var';
// utils styles
@import './utils/mixins';
@import './utils/placeholders';
// components styles
@import './backdrop';
@import '../../components/loader/loader';

html {
  scroll-behavior: smooth;
}

body.isLoading,
body.modalIsOpen,
body.mobileMenuIsOpen {
  overflow: hidden;
}

body {
  position: relative;

  @extend %flex-dir-column;
  min-height: 100vh;

  font-size: 16px;
  line-height: 1.2;
  font-family: var(--font--body);
  color: var(--color--black);
}
main {
  flex-grow: 1;
}
footer {
  margin-top: auto;
}

svg {
  fill: currentColor;
  pointer-events: none;
}
button,
a {
  @extend %cursor;
}

button:focus-visible,
a:focus-visible,
input:focus-visible,
textarea:focus-visible {
  outline: 2px solid var(--color--green);
  border-radius: 2px;
}

.section {
  padding: 16px 0;

  @include breakpoint-min($medium) {
    padding: 32px 0;
  }
}
.container {
  width: 100%;
  padding: 0 var(--padding-small);
  margin: 0 auto;

  @include breakpoint-min($small) {
    width: $small;
  }

  @include breakpoint-min($medium) {
    width: $medium;
    padding: 0 $padding-medium;
  }

  @include breakpoint-min($large) {
    width: $large;
  }
}

.title {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 800;
}
.subtitle {
  font-family: var(--font--title);
  font-size: 24px;

  @include breakpoint-min($large) {
    font-size: 28px;
  }
}

.list {
  list-style: none;
}
.link {
  text-decoration: none;
  color: inherit;
}
