@import '/src/common/scss/common.scss';
@import '/src/components/footer/footer.scss';
@import '/src/components/loader/loader.scss';

// components styles
@import '../../components/btns/btns.scss';
@import '../../components/logo/logo.scss';
@import '../../components/nav/nav.scss';
@import '../../components/contacts/contacts.scss';
@import '../../components/social-media/social-media.scss';
@import '../../components/modal-form/modal-form.scss';
@import '../../components/mobile-menu/mobile-menu.scss';
@import '../../components/header/header.scss';

// sections styles
@import '../../components/section/hero/hero.scss';
@import '../../components/section/services/services.scss';
@import '../../components/section/prices/prices.scss';
@import '../../components/section/numbers/numbers.scss';
@import '../../components/section/team/team.scss';
@import '../../components/section/about/about.scss';
@import '../../components/section/steps/steps.scss';
@import '../../components/section/customers/customers.scss';
@import '../../components/section/partners/partners.scss';
@import '../../components/section/contacts/contacts.scss';
