
// .about {
// }
// .about__title {
// }
.about__list {
  @extend %flex-dir-column;
  gap: var(--gap);
}
.about__item {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.about__item:not(:nth-child(3)) {
  @include breakpoint-min($medium) {
    flex-direction: row;
  }
}
.about__item:nth-child(3) {
  @include breakpoint-min($large) {
    flex-direction: row;
  }
}

.about_img {
  flex: 0 0 calc((100% - var(--gap)) / 2);
  width: calc((100% - var(--gap)) / 2);
  aspect-ratio: 4 / 3;
  object-fit: cover;

  @include breakpoint-max($medium - 1) {
    width: 100%;
  }

  @include breakpoint-min($medium) {
    .about__item:nth-child(2) & {
      order: 2;
    }
  }

  @include breakpoint-min-max($medium, $large - 1) {
    .about__item:nth-child(3) & {
      display: none;
      // width: 100%;
      // height: 100px;
      // aspect-ratio: 9 / 3;
      // object-position: right;
    }
  }

  @include breakpoint-min($large) {
    flex: 0 0 40%;
    width: 40%;
  }
}





.about__text {
  padding: var(--gap);
  
  background-color: var(--color--white);
}
// .about__accent {
// }

.about__sublist {
  @extend %flex-dir-column;
  --gap: 8px;
  gap: var(--gap);

  margin-top: 16px;
}

.about__subitem {
  display: flex;
  --gap:12px;
  gap: var(--gap);
}

.about__subitem .list-maker {
    flex: 0 0 12px;
    height: 12px;
  }

.about__subitem .list-maker--number {
  text-align: center;
  font-weight: 600;
}