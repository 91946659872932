.header {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  min-height: 60px;
  padding: 8px 0;

  color: var(--color--black);

  background-color: transparent;
  z-index: 10;

  transition: all 400ms linear;

  body.mobileMenuIsOpen & {
    background-color: #fff;
  }

  body.isScrolling & {
    opacity: 0;
  }

  body.isScrolling &.white {
    position: fixed;
    color: var(--color--black);

    background-color: #fff;
    box-shadow: 0 1px 5px 0 rgba(0,0,0, 0.1);
    opacity: 1;
  }
}

.header .container {
  @extend %flex-center-a;
  // justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  

  @include breakpoint-max($medium - 1) {
    justify-content: space-between;
    align-items: center;
  }
}
