.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.9);
  z-index: 3000;
  overflow: scroll;


  @include breakpoint-min-width-height($medium, $medium) {
    display: flex;
    align-items: center;
  }
}
