.contacts {
  padding-top: calc(var(--header-h) + var(--section-p-top));
  background: linear-gradient(
    to bottom,
    var(--color--white) var(--header-h),
    var(--color--black) var(--header-h) 100%,
  );

  @include breakpoint-max($medium - 1) {
    padding-top: calc(var(--header-h) + var(--section-p-top) / 2);
  }
}

.contacts .container {
  display: flex;
  flex-wrap: wrap;
  --gap: 32px;
  column-gap: var(--gap);

  @include breakpoint-max($medium - 1) {
    flex-direction: column;
  }

  @include breakpoint-min($large) {
    --gap: 64px;
  }
}

.contacts__title {
  flex: 0 0 100%;

  color: var(--color--white);
}

.contacts__box {
  flex: 0 0 calc((100% - var(--gap)) / 2);
  padding: 32px;
  
  background-color: var(--color--white);
}

.contacts__box--1 {
  @extend %flex-dir-column;
  gap: 32px;

  @include breakpoint-max($medium - 1) {
    padding-bottom: 0;
  }
}

.contact {
  font-size: 24px;
  font-weight: 700;
  color: var(--color--white);;
}

#contactForm,
#contactForm label {
  @extend %flex-dir-column;
  gap: 8px;
}

#contactForm {
  margin-top: 20px;
  gap: 16px;
}


#contactForm input,
#contactForm textarea {
  width: 100%;
  height: 40px;
  padding: 4px 12px;

  @extend %border-radius;
}

#contactForm textarea {
  padding: 12px;
  height: 80px;
  resize: none;
}

.contact__title {
  margin-bottom: 8px;

  // color: var(--color--white);
}

.contact__link:focus-visible {
  outline: none;
}

// .wrap {
//   @extend %flex-dir-column;
//   gap: 24px;
//   padding: 32px;
// }

// .contact__link--email 
.contact__link--tel {
  font-size: 20px;
  font-weight: 700;
}

.work-schedule {
  margin-top: 8px;
}
