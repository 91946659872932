.messagers__title {
  margin-bottom: 8px;

  // color: var(--color--white);
}

.messagers__list {
  @extend %flex-dir-column;
  gap: 4px;
}

.messagers__link {
  @extend %flex-center-a;
  gap: 8px;
  width: 160px;
  height: 40px;
  padding: 0 8px;

  transition: all 300ms linear;
}

.messagers__link:focus-visible {outline: none;}
.messagers__link:hover, .messagers__link:focus {color: var(--color--white);}
.messagers__link--telegram:hover, .messagers__link--telegram:focus {background: #08c;};
.messagers__link--viber:hover, .messagers__link--viber:focus {background: #59267c;};
.messagers__link--whatsapp:hover, .messagers__link--whatsapp:focus {background: #25d366;};


.messagers__icon {
  flex: 0 0 20px;
  height: 20px;
  width: 20px;
}