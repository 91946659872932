:root {
  // ===== fonts =====
  // ===== var 1 =====
  --font--title: 'Ysabeau SC', sans-serif;
  --font--body: 'Montserrat', sans-serif;

  // ===== colors =====
  --color--white: rgb(240, 240, 250);
  --color--black: rgb(0, 0, 0);
  --color--green: rgb(90, 160, 90);

  // ===== decor ===
  --box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  // ===== transition ===
  --transition: 500ms ease;
  // === size  ===
  --padding-small: 16px;
  --border-radius: 0px;
  // ===== card set ===
  --items: 1;
  --gap: 16px;
  // --header-h: 76px;
  --header-h: 60px;
  --section-p-top: 32px;
}

// ===== screen sizes ===
$small: 480px;
$medium: 768px;
$large: 1200px;


// ===== screen sizes ===

// ===== sizes ===
$padding-small: 16px;
$padding-medium: calc($padding-small * 2);
$border-radius: 12px;


// rgb(36, 36, 36);