.contact__link--email {
  display: inline-flex;;
  align-items: center;
  gap: 8px;
  height: 40px;
  max-width: 280px;
  margin-bottom: 4px;
  padding: 0 8px;

  transition: all 300ms linear;
}

.contact__link--email:hover, .contact__link--email:focus {
  color: var(--color--white);
  background-color: var(--color--green);
}

.email__icon {
  flex: 0 0 20px;
  height: 20px;
  width: 20px;
}