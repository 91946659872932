.modal-form__backdrop {
transition: all 100ms linear;

  .modal-form.isHidden & {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}


.modal-form__content {
  width: 100%;
  margin: 0 auto;

  background-color: var(--color--white);
  @extend %border-radius;
  transition: all 200ms linear;

  .modal-form.isHidden & {
    transform: scale(0.8);
  }

  @include breakpoint-min($small) {
    width: $small;
  }
}

.modal-form__btn--open {
  transition: all 300ms linear;

  .header & {
    width: 160px;
  }
}


.modal__top {
  padding: 12px;
}

.modal-form__btn--close {
  margin-left: auto;
}
.modal__middle {
  @extend %relative;

  @extend %flex-dir-column;
  gap: 16px;
  --padding: 32px;
  padding: 0 var(--padding);
}
.modal__bottom {
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

.modal__bottom div {
  @extend %flex-dir-column;
  gap: 4px;
}

#modalForm {
  margin-top: 20px;
}

#modalForm,
#modalForm label {
  @extend %flex-dir-column;
  gap: 8px;
}

#modalForm {
  gap: 16px;
}

#modalForm input,
#modalForm textarea {
  width: 100%;
  height: 40px;
  padding: 4px 12px;

  @extend %border-radius;
}

#modalForm textarea {
  padding: 12px;
  height: 80px;
  resize: none;
}

.tel--modal {
  margin-top: auto;
}

.modal__msg {
  @extend %absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 

  @extend %flex-center-ja;
  flex-direction: column;
  gap: 20px;
  padding: 0 var(--padding);

  background-color: var(--color--white);
}
.modal__msg.isHidden {display: none;}
.modal__msg--success strong {color: var(--color--green);}
.modal__msg--error strong {color: red;}