.numbers {
  padding-top: calc(var(--header-h) + var(--section-p-top));

  background: linear-gradient(
    to bottom,
    var(--color--white) var(--header-h),
    // var(--color--green) var(--header-h) 100%,
    #fff var(--header-h) 100%,
  );

  @include breakpoint-max($medium - 1) {
    padding-top: calc(var(--header-h) + var(--section-p-top) / 2);
  }
}

.numbers__list {
  @extend %cardSet;
  justify-content: start;
}

.numbers__item {
  
  @extend %cardSet__item;

  @include breakpoint-min($medium) {
    --items: 3;
  }
  @include breakpoint-min($large) {
    --items: 5;
  }
}

.numbers__value {
  font-size: 50px;
  font-weight: 800;
}