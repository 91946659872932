.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // padding-top: calc(28px + 88px);
  padding-top: calc(28px + 64px);
  padding-bottom: 32px;

  background: var(--color--green, rgb(90, 160, 90));

  @include breakpoint-min($medium) {
    // padding-top: calc(40px + 88px);
    padding-top: calc(40px + 64px);
  }
  @include breakpoint-min($large) {
    // padding-top: calc(100px + 88px);
    padding-top: calc(100px + 64px);
  }
}

.hero__title {
  @extend %flex-dir-column;
  gap: 16px;
  margin-bottom: 20px;

  font-size: 28px;
  font-weight: 900;

  & .hero__title--max {
    font-size: 40px;
  
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  @include breakpoint-min($medium) {
    font-size: 40px;
    font-weight: 900;

    & .hero__title--max {
      font-size: 60px;

      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
}

.hero__subtitle {
  font-size: 20px;
  font-weight: 500;

  &:first-of-type,
  &:last-of-type {
    margin-bottom: 20px;
  }
}


.hero__btn.modal-form__btn--open {
  width: 240px;
}