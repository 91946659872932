.tel--header,
.tel--modal {
  font-size: 20px;
  font-weight: 700;
}

.contact__link--tel {
  display: inline-flex;
  align-items: center;
  // max-width: 280px;
  height: 40px;
  padding: 0 8px;
}

.contact__link--tel:hover, .contact__link--tel:focus {
  color: var(--color--white);
  background-color: var(--color--green);
}