.team {
  padding-bottom: 0;
  background-color: var(--color--white);
}
.team__list {
  @extend %cardSet;
  justify-content: flex-start;
}
.team__item {
  @extend %cardSet__item;
  flex-grow: 0;
  flex-shrink: 0;
  @extend %flex-dir-column;
  gap: 12px;
  // padding: 16px;

  background-color: var(--color--white);;
  overflow: hidden;

  @include breakpoint-min($medium) {
    --items: 4;
  }
}


.team-member__link {
  position: relative;

  margin-bottom: 8px;

  color: currentColor;
}

.team-member__img {
  aspect-ratio: 3 / 4;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.team-member__name {
  font-weight: 600;
}

.team-member__overlay {
  position: absolute;
  top: 0;
  left: 0;

  @extend %flex-center-ja;
  width: 100%;
  height: 100%;

  color: var(--color--green, rgb(90, 160, 90));
  background-color: var(--color--green, rgb(90, 160, 90));
  opacity: 0;
  // scale: 0;

  transition: all 200ms linear;

  .team-member__link:hover & {
    opacity: 1;
    // scale: 1;
  }
}

.team-member__link-icon {
  width: 120px;
  height: 120px;
  padding: 20px;

  background-color: var(--color--black);
  border-radius: 8px;
}
