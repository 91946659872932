.chat {
  position: fixed;
  bottom: 90px;
  right: 20px;

  display: flex;
  flex-direction: column-reverse;
  gap: 8px;

  transition: all 300ms linear;
  &.isHidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.btn-chat {
  @extend %flex-center-ja;
  width: 48px;
  height: 48px;

  color: var(--color--white);

  background-color: var(--color--black);
  border-color: var(--color--white);
  border: 2px solid;
  border-radius: 50%;
  transition: all 300ms linear;
}
.btn-chat:hover,
.btn-chat:focus {
  transform: scale(1.1);
}
.btn-chat__icon {
  transition: all 300ms linear;

  .btn-chat.active & {
    transform: scale(0);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}
.btn-chat.btn-cross:before, .btn-chat.btn-cross:after {
  background-color: var(--color--white);
}

.chat__list {
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;

  transition: all 300ms linear;
  &.isHidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}
.chat__item {
  transition: all 300ms linear;

  .chat__list.isHidden & {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.chat__item:nth-child(1) {transition-delay: 100ms}
.chat__item:nth-child(2) {transition-delay: 200ms}
.chat__item:nth-child(3) {transition-delay: 300ms}


.chat__link {
  @extend %flex-center-ja;
  width: 48px;
  height: 48px;

  color: var(--color--white);

  border-radius: 50%;
  transition: all 300ms linear;
}
.chat__link:hover,
.chat__link:focus {
  transform: scale(1.1);
}
.chat__link--telegram{background-color: #08c;}
.chat__link--viber{background-color: #59267c;}
.chat__link--whatsapp{background-color: #25d366;}