// .prices {
// }
// .prices__title {
// }
// .prices__subtitle {
// }

// .prices__box {
//   margin-top: 60px;
// }
.prices__list {
  @extend %cardSet;
}
.prices__item {
  @extend %cardSet__item;
  flex-grow: 0;
  flex-shrink: 0;
  @extend %flex-dir-column;
  gap: 4px;
}

.price--recruitment {
  @include breakpoint-min($medium) {
    --items: 3;
  }
}

.price__value {
  font-size: 50px;
  font-weight: 800;
}

.price__value span {
  font-size: 25px;
}

.price__title {
  font-weight: 600;
}
// .price__text {

// }

.price__text--right {
  // @extend .price__text;

  margin-left: auto;
}