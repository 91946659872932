.partners__list {
  @extend %cardSet;
  justify-content: flex-start;
  // gap: 32px;
  margin-top: 20px;
}

.partners__item {
  @extend %flex-center-ja;
  @extend %cardSet__item;
  flex-grow: 0;
  flex-shrink: 0;
  height: 80px;
  padding: 20px;

  background-color: var(--color--white);
  box-shadow: var(--box-shadow);
  transition: all 300ms linear;

  &:hover {
    transform: scale(1.05);
  }
  
  --items: 2;
  @include breakpoint-min($medium) {
    --items: 4;
  }
  @include breakpoint-min($large) {
    --items: 5;
  }
}

.partners__icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}