.header__nav {
  @include breakpoint-max($medium - 1) {
    display: none;
  }
}

.nav__list {
  display: flex;
  gap: var(--gap);

  .mobile-menu__nav & {
    flex-direction: column;
  }
}
