@import './mobile-menu-btn.scss';

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;

  @extend %flex-dir-column;
  width: 100%;
  height: 100%;

  background-color: var(--color--white);
  box-shadow: var(--box-shadow);
  transition: all var(--transition);
  overflow-y: scroll;
  overflow-y: hidden;
  z-index: 3;

  &.isHidden {
    top: -101%;

    button, a {
      pointer-events: none;
      visibility: hidden;
    }
  }

  .nav__link {
    font-size: 28px;
    flex-wrap: 800;
  }

  // @include breakpoint-min($small) {
  //   width: 320px;
  // }
}

.mobile-menu__content {
  background-color: var(--color--white);
}

.mobile-menu__header {
  height: 80px;
}

.mobile-menu__body {
  padding: 16px 0;
}

.mobile-menu__footer {
  margin-top: auto;
  padding: 16px 0;

  border-top: 1px solid;
  border-color: #fff
}

.mobile-menu__footer .container {
  @extend %flex-dir-column;
  gap: 16px;
}
