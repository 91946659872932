*, :before, :after {
  box-sizing: border-box;
}

* {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  line-height: 1.5;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img, picture, video, canvas, svg, iframe {
  max-width: 100%;
  display: block;
}

img {
  vertical-align: top;
}

ol, ul, li {
  list-style: none;
}

input, button, textarea, select {
  font: inherit;
}

button[disabled] {
  cursor: auto;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
}

:root {
  --font--title: "Ysabeau SC", sans-serif;
  --font--body: "Montserrat", sans-serif;
  --color--white: #f0f0fa;
  --color--black: #000;
  --color--green: #5aa05a;
  --box-shadow: 0 2px 2px #0003;
  --transition: .5s ease;
  --padding-small: 16px;
  --border-radius: 0px;
  --items: 1;
  --gap: 16px;
  --header-h: 60px;
  --section-p-top: 32px;
}

.partners__list, section.customers .customers__list, .steps__list, .team__list, .numbers__list, .prices__list {
  justify-content: center;
  gap: var(--gap, 16px);
  flex-wrap: wrap;
  display: flex;
}

.partners__item, section.customers .customers__item, .steps__item, .team__item, .numbers__item, .prices__item {
  flex-basis: calc((100% - var(--gap, 16px) * (var(--items)  - 1)) / var(--items));
}

@media (min-width: 768px) {
  .partners__item, section.customers .customers__item, .steps__item, .team__item, .numbers__item, .prices__item {
    --items: 2;
  }
}

@media (min-width: 1200px) {
  .partners__item, section.customers .customers__item, .steps__item, .team__item, .numbers__item, .prices__item {
    --items: 3;
  }
}

.header .container, .messagers__link, .footer__bottom .container, footer, .partners__item, .customers__item, .team-member__overlay, .mobile-menu__btn, .modal__msg, .chat__link, .btn-chat, .btn-up, .btn-cross, .btn, .loader__backdrop {
  align-items: center;
  display: flex;
}

.partners__item, .customers__item, .team-member__overlay, .mobile-menu__btn, .modal__msg, .chat__link, .btn-chat, .btn-up, .btn-cross, .btn, .loader__backdrop {
  justify-content: center;
}

#contactForm, #contactForm label, .contacts__box--1, .about__sublist, .about__list, .team__item, .prices__item, .service-desc__list, .service__text, .service__desc, .services__tabs-content, .hero__title, .mobile-menu__footer .container, .mobile-menu, #modalForm, #modalForm label, .modal__bottom div, .modal__middle, .messagers__list, body {
  flex-direction: column;
  display: flex;
}

.modal__msg, .btn-cross:before, .btn-cross:after {
  position: absolute;
}

.modal__middle {
  position: relative;
}

.btn-cross, .btn, button, a {
  cursor: pointer;
}

#contactForm input, #contactForm textarea, .mobile-menu__btn .burger-line, #modalForm input, #modalForm textarea, .modal-form__content, .btn-cross:before, .btn-cross:after, .btn {
  border-radius: var(--border-radius);
}

.backdrop {
  width: 100%;
  height: 100%;
  z-index: 3000;
  background-color: #000000e6;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}

@media (min-width: 768px) and (min-height: 768px) {
  .backdrop {
    align-items: center;
    display: flex;
  }
}

.isHidden .loader__backdrop {
  display: none;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
  display: block;
  position: absolute;
}

.lds-roller div:nth-child(1) {
  animation-delay: -.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  background: var(--color--green, #5aa05a);
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}

body.isLoading, body.modalIsOpen, body.mobileMenuIsOpen {
  overflow: hidden;
}

body {
  min-height: 100vh;
  font-size: 16px;
  line-height: 1.2;
  font-family: var(--font--body);
  color: var(--color--black);
  position: relative;
}

main {
  flex-grow: 1;
}

footer {
  margin-top: auto;
}

svg {
  fill: currentColor;
  pointer-events: none;
}

button:focus-visible, a:focus-visible, input:focus-visible, textarea:focus-visible {
  outline: 2px solid var(--color--green);
  border-radius: 2px;
}

.section {
  padding: 16px 0;
}

@media (min-width: 768px) {
  .section {
    padding: 32px 0;
  }
}

.container {
  width: 100%;
  padding: 0 var(--padding-small);
  margin: 0 auto;
}

@media (min-width: 480px) {
  .container {
    width: 480px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 768px;
    padding: 0 32px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

.title {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 800;
}

.subtitle {
  font-family: var(--font--title);
  font-size: 24px;
}

@media (min-width: 1200px) {
  .subtitle {
    font-size: 28px;
  }
}

.list {
  list-style: none;
}

.link {
  color: inherit;
  text-decoration: none;
}

footer {
  color: var(--color--white);
  background-color: var(--color--black);
  flex-direction: column;
  margin-top: auto;
}

.footer__top, .footer__bottom {
  width: 100%;
  padding: 16px 0;
}

.footer__bottom .container {
  justify-content: space-between;
}

@media (max-width: 767px) {
  .footer__bottom .container {
    flex-direction: column;
    gap: 12px;
  }
}

.copyright, .developer {
  text-transform: uppercase;
  color: var(--color--white);
  font-size: 12px;
}

.developer__link {
  font-weight: 700;
}

.isHidden .loader__backdrop {
  display: none;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
  display: block;
  position: absolute;
}

.lds-roller div:nth-child(1) {
  animation-delay: -.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  background: var(--color--green, #5aa05a);
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

.btn {
  min-width: 80px;
  height: 40px;
  color: inherit;
  background-color: inherit;
  transition: all .3s linear;
}

.btn:hover, .btn:focus {
  transform: scale(1.02);
}

.btn--green {
  color: var(--color--white);
  background-color: var(--color--green);
}

.btn--black {
  color: var(--color--white);
  background-color: var(--color--black);
}

.btn-cross {
  width: 24px;
  height: 24px;
  background-color: #0000;
  position: relative;
}

.btn-cross:before, .btn-cross:after {
  content: "";
  width: 20px;
  height: 2px;
  background-color: #000;
}

.btn-cross:before {
  transform: rotate(-45deg);
}

.btn-cross:after {
  transform: rotate(45deg);
}

.btn-up {
  width: 48px;
  height: 48px;
  color: var(--color--white);
  background-color: var(--color--black);
  border: 2px solid;
  border-color: var(--color--white);
  border-radius: 50%;
  transition: all .3s linear;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.btn-up.isHidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.btn-up:hover, .btn-up:focus {
  transform: scale(1.1);
}

.chat {
  flex-direction: column-reverse;
  gap: 8px;
  transition: all .3s linear;
  display: flex;
  position: fixed;
  bottom: 90px;
  right: 20px;
}

.chat.isHidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.btn-chat {
  width: 48px;
  height: 48px;
  color: var(--color--white);
  background-color: var(--color--black);
  border-color: var(--color--white);
  border: 2px solid;
  border-radius: 50%;
  transition: all .3s linear;
}

.btn-chat:hover, .btn-chat:focus {
  transform: scale(1.1);
}

.btn-chat__icon {
  transition: all .3s linear;
}

.btn-chat.active .btn-chat__icon {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: scale(0);
}

.btn-chat.btn-cross:before, .btn-chat.btn-cross:after {
  background-color: var(--color--white);
}

.chat__list {
  flex-direction: column-reverse;
  gap: 8px;
  transition: all .3s linear;
  display: flex;
}

.chat__list.isHidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.chat__item {
  transition: all .3s linear;
}

.chat__list.isHidden .chat__item {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.chat__item:nth-child(1) {
  transition-delay: .1s;
}

.chat__item:nth-child(2) {
  transition-delay: .2s;
}

.chat__item:nth-child(3) {
  transition-delay: .3s;
}

.chat__link {
  width: 48px;
  height: 48px;
  color: var(--color--white);
  border-radius: 50%;
  transition: all .3s linear;
}

.chat__link:hover, .chat__link:focus {
  transform: scale(1.1);
}

.chat__link--telegram {
  background-color: #08c;
}

.chat__link--viber {
  background-color: #59267c;
}

.chat__link--whatsapp {
  background-color: #25d366;
}

.logo {
  width: 80px;
  max-height: 60px;
  font-family: var(--font--title);
  font-size: 32px;
  font-weight: 600;
  transition: all .3s linear;
}

@media screen and (min-width: 768px) {
  .logo {
    font-size: 40px;
    font-weight: 600;
  }
}

.logo:hover .logo__icon, .logo:focus .logo__icon {
  transform: scale(1.02);
}

.logo__icon {
  width: 80px;
  height: 40px;
}

.logo__text {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  max-height: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.header__logo {
  height: 40px;
  color: var(--color--black);
  margin-right: auto;
}

.header__logo .logo__icon {
  height: 28px;
}

.header__logo .logo__text {
  height: 12px;
  font-size: 12px;
}

.modal-form__logo {
  color: var(--color--green);
}

.contacts__logo {
  color: var(--color--white);
  margin-top: auto;
  display: block;
}

@media (max-width: 767px) {
  .header__nav {
    display: none;
  }
}

.nav__list {
  gap: var(--gap);
  display: flex;
}

.mobile-menu__nav .nav__list {
  flex-direction: column;
}

.tel--header, .tel--modal {
  font-size: 20px;
  font-weight: 700;
}

.contact__link--tel {
  height: 40px;
  align-items: center;
  padding: 0 8px;
  display: inline-flex;
}

.contact__link--tel:hover, .contact__link--tel:focus {
  color: var(--color--white);
  background-color: var(--color--green);
}

.contact__link--email {
  height: 40px;
  max-width: 280px;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  padding: 0 8px;
  transition: all .3s linear;
  display: inline-flex;
}

.contact__link--email:hover, .contact__link--email:focus {
  color: var(--color--white);
  background-color: var(--color--green);
}

.email__icon {
  height: 20px;
  width: 20px;
  flex: 0 0 20px;
}

.messagers__title {
  margin-bottom: 8px;
}

.messagers__list {
  gap: 4px;
}

.messagers__link {
  width: 160px;
  height: 40px;
  gap: 8px;
  padding: 0 8px;
  transition: all .3s linear;
}

.messagers__link:focus-visible {
  outline: none;
}

.messagers__link:hover, .messagers__link:focus {
  color: var(--color--white);
}

.messagers__link--telegram:hover, .messagers__link--telegram:focus {
  background: #08c;
}

.messagers__link--viber:hover, .messagers__link--viber:focus {
  background: #59267c;
}

.messagers__link--whatsapp:hover, .messagers__link--whatsapp:focus {
  background: #25d366;
}

.messagers__icon {
  height: 20px;
  width: 20px;
  flex: 0 0 20px;
}

.social-media__title {
  margin-bottom: 8px;
}

.social-media__list {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.social-media__link {
  height: 40px;
  max-width: 160px;
  place-items: center;
  gap: 8px;
  margin-bottom: 4px;
  padding: 0 8px;
  transition: all .3s linear;
  display: flex;
}

.social-media__link:focus-visible {
  outline: none;
}

.social-media__link:hover, .social-media__link:focus {
  color: var(--color--white);
}

.social-media__link--linkedin:hover, .social-media__link--linkedin:focus {
  background: #0a66c2;
}

.social-media__link--instagram:hover, .social-media__link--instagram:focus {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#f09433", endColorstr= "#bc1888", GradientType= 1);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}

.wrap {
  background-color: var(--color--white);
}

.social-media__icon {
  height: 20px;
  width: 20px;
  flex: 0 0 20px;
}

.modal-form__backdrop {
  transition: all .1s linear;
}

.modal-form.isHidden .modal-form__backdrop {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.modal-form__content {
  width: 100%;
  background-color: var(--color--white);
  margin: 0 auto;
  transition: all .2s linear;
}

.modal-form.isHidden .modal-form__content {
  transform: scale(.8);
}

@media (min-width: 480px) {
  .modal-form__content {
    width: 480px;
  }
}

.modal-form__btn--open {
  transition: all .3s linear;
}

.header .modal-form__btn--open {
  width: 160px;
}

.modal__top {
  padding: 12px;
}

.modal-form__btn--close {
  margin-left: auto;
}

.modal__middle {
  --padding: 32px;
  padding: 0 var(--padding);
  gap: 16px;
}

.modal__bottom {
  justify-content: space-between;
  padding: 32px;
  display: flex;
}

.modal__bottom div {
  gap: 4px;
}

#modalForm {
  margin-top: 20px;
}

#modalForm, #modalForm label {
  gap: 8px;
}

#modalForm {
  gap: 16px;
}

#modalForm input, #modalForm textarea {
  width: 100%;
  height: 40px;
  padding: 4px 12px;
}

#modalForm textarea {
  height: 80px;
  resize: none;
  padding: 12px;
}

.tel--modal {
  margin-top: auto;
}

.modal__msg {
  padding: 0 var(--padding);
  background-color: var(--color--white);
  flex-direction: column;
  gap: 20px;
  inset: 0;
}

.modal__msg.isHidden {
  display: none;
}

.modal__msg--success strong {
  color: var(--color--green);
}

.modal__msg--error strong {
  color: red;
}

.mobile-menu__btn {
  width: 40px;
  height: 40px;
  color: var(--color--antiquewhite);
  background-color: #0000;
  position: relative;
}

body.mobileMenuIsOpen .mobile-menu__btn {
  z-index: 20;
}

@media (min-width: 768px) {
  .mobile-menu__btn {
    display: none;
  }
}

.mobile-menu__btn .burger-line {
  width: 28px;
  height: 2px;
  background-color: currentColor;
  margin: 0;
  transition-property: height, opacity, transform;
  transition-duration: .4s;
  position: absolute;
}

.burger-line:first-child {
  transform: translateY(8px);
}

.burger-line:last-child {
  transform: translateY(-8px);
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:first-child {
  transform: rotate(-45deg);
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:nth-child(2n) {
  opacity: 0;
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:last-child {
  transform: rotate(45deg);
}

.mobile-menu {
  width: 100%;
  height: 100%;
  background-color: var(--color--white);
  box-shadow: var(--box-shadow);
  transition: all var(--transition);
  z-index: 3;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: hidden;
}

.mobile-menu.isHidden {
  top: -101%;
}

.mobile-menu.isHidden button, .mobile-menu.isHidden a {
  pointer-events: none;
  visibility: hidden;
}

.mobile-menu .nav__link {
  flex-wrap: 800;
  font-size: 28px;
}

.mobile-menu__content {
  background-color: var(--color--white);
}

.mobile-menu__header {
  height: 80px;
}

.mobile-menu__body {
  padding: 16px 0;
}

.mobile-menu__footer {
  border-color: #fff;
  border-top-style: solid;
  border-top-width: 1px;
  margin-top: auto;
  padding: 16px 0;
}

.mobile-menu__footer .container {
  gap: 16px;
}

.header {
  width: 100%;
  min-height: 60px;
  color: var(--color--black);
  z-index: 10;
  background-color: #0000;
  padding: 8px 0;
  transition: all .4s linear;
  position: absolute;
  top: 0;
  left: 0;
}

body.mobileMenuIsOpen .header {
  background-color: #fff;
}

body.isScrolling .header {
  opacity: 0;
}

body.isScrolling .header.white {
  color: var(--color--black);
  opacity: 1;
  background-color: #fff;
  position: fixed;
  box-shadow: 0 1px 5px #0000001a;
}

.header .container {
  flex-wrap: wrap;
  gap: 16px;
}

@media (max-width: 767px) {
  .header .container {
    justify-content: space-between;
    align-items: center;
  }
}

.hero {
  min-height: 100vh;
  background: var(--color--green, #5aa05a);
  flex-direction: column;
  padding-top: 92px;
  padding-bottom: 32px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .hero {
    padding-top: 104px;
  }
}

@media (min-width: 1200px) {
  .hero {
    padding-top: 164px;
  }
}

.hero__title {
  gap: 16px;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 900;
}

.hero__title .hero__title--max {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 40px;
}

@media (min-width: 768px) {
  .hero__title {
    font-size: 40px;
    font-weight: 900;
  }

  .hero__title .hero__title--max {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 60px;
  }
}

.hero__subtitle {
  font-size: 20px;
  font-weight: 500;
}

.hero__subtitle:first-of-type, .hero__subtitle:last-of-type {
  margin-bottom: 20px;
}

.hero__btn.modal-form__btn--open {
  width: 240px;
}

.services {
  padding-top: calc(var(--header-h)  + var(--section-p-top));
  background: linear-gradient(to bottom, var(--color--white) var(--header-h), var(--color--white) var(--header-h) 100%);
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .services {
    padding-top: calc(var(--header-h)  + var(--section-p-top) / 2);
  }
}

.services__tabs {
  display: flex;
}

@media (max-width: 767px) {
  .services__tabs {
    flex-direction: column;
  }
}

.services__tabs-content {
  gap: 20px;
}

.tabs-content__list, .services__prices {
  background-color: #fff;
  padding: 16px;
}

.tabs-content__list {
  padding-top: 0;
}

.price__wrap {
  padding: 16px;
}

.services__tabs-nav {
  background-color: var(--color--white);
}

@media (min-width: 768px) {
  .services__tabs-nav {
    flex: 0 0 240px;
  }
}

.services__tabs-nav .tabs-nav__btn {
  width: 100%;
  height: 60px;
  text-align: start;
  background-color: var(--color--white);
  padding: 0 16px;
}

.services__tabs-nav .tabs-nav__btn.current {
  color: #f5f5f5;
  background-color: var(--color--green);
}

.tabs-content__item.isHidden {
  pointer-events: none;
  display: none;
}

.service__title {
  height: 60px;
  border-bottom: 1px solid var(--color--white);
  padding: 16px 0;
  font-size: 28px;
  font-weight: 700;
}

.service__desc:not(:last-child) {
  border-bottom: 1px solid var(--color--white);
  padding-bottom: 16px;
}

.service__desc {
  --gap: 12px;
  gap: var(--gap);
  padding-top: 16px;
}

.service__text {
  gap: var(--gap);
}

.service-desc__extra-content-inner .service__text {
  margin-top: var(--gap);
}

.service__text span {
  font-weight: 600;
}

.service-desc__extra-content {
  grid-template-rows: 1fr;
  transition: grid-template-rows .3s linear;
  display: grid;
}

.service-desc__extra-content.isHidden {
  grid-template-rows: 0fr;
}

.service-desc__extra-content-inner {
  overflow: hidden;
}

.service-desc__list {
  gap: var(--gap);
}

.service-desc__item {
  --gap: 12px;
  gap: var(--gap);
  display: flex;
}

.service-desc__item .list-maker {
  height: 20px;
  flex: 0 0 20px;
}

.service-desc__item .list-maker--number {
  text-align: center;
  font-weight: 600;
}

.service-desc__item .list-maker--square {
  position: relative;
}

.service-desc__item .list-maker--square:before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--color--black);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.service-desc__btn {
  background-color: var(--color--white);
}

.services__tabs-content .prices__title {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
}

.services__tabs-content .prices__list {
  justify-content: start;
}

.prices__box.isHidden {
  pointer-events: none;
  display: none;
}

.price--consulting, .price--school {
  --items: 2;
}

.price--consulting .price__value, .price--school .price__value {
  margin-top: auto;
}

@media (max-width: 1199px) {
  .price--consulting .price__value, .price--school .price__value {
    flex-direction: column;
    display: flex;
  }
}

.prices__item {
  flex-grow: 0;
  flex-shrink: 0;
  gap: 4px;
}

@media (min-width: 768px) {
  .price--recruitment {
    --items: 3;
  }
}

.price__value {
  font-size: 50px;
  font-weight: 800;
}

.price__value span {
  font-size: 25px;
}

.price__title {
  font-weight: 600;
}

.price__text--right {
  margin-left: auto;
}

.numbers {
  padding-top: calc(var(--header-h)  + var(--section-p-top));
  background: linear-gradient(to bottom, var(--color--white) var(--header-h), #fff var(--header-h) 100%);
}

@media (max-width: 767px) {
  .numbers {
    padding-top: calc(var(--header-h)  + var(--section-p-top) / 2);
  }
}

.numbers__list {
  justify-content: start;
}

@media (min-width: 768px) {
  .numbers__item {
    --items: 3;
  }
}

@media (min-width: 1200px) {
  .numbers__item {
    --items: 5;
  }
}

.numbers__value {
  font-size: 50px;
  font-weight: 800;
}

.team {
  background-color: var(--color--white);
  padding-bottom: 0;
}

.team__list {
  justify-content: flex-start;
}

.team__item {
  background-color: var(--color--white);
  flex-grow: 0;
  flex-shrink: 0;
  gap: 12px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .team__item {
    --items: 4;
  }
}

.team-member__link {
  color: currentColor;
  margin-bottom: 8px;
  position: relative;
}

.team-member__img {
  aspect-ratio: 3 / 4;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.team-member__name {
  font-weight: 600;
}

.team-member__overlay {
  width: 100%;
  height: 100%;
  color: var(--color--green, #5aa05a);
  background-color: var(--color--green, #5aa05a);
  opacity: 0;
  transition: all .2s linear;
  position: absolute;
  top: 0;
  left: 0;
}

.team-member__link:hover .team-member__overlay {
  opacity: 1;
}

.team-member__link-icon {
  width: 120px;
  height: 120px;
  background-color: var(--color--black);
  border-radius: 8px;
  padding: 20px;
}

.about__list {
  gap: var(--gap);
}

.about__item {
  gap: var(--gap);
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .about__item:not(:nth-child(3)) {
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .about__item:nth-child(3) {
    flex-direction: row;
  }
}

.about_img {
  flex: 0 0 calc((100% - var(--gap)) / 2);
  width: calc((100% - var(--gap)) / 2);
  aspect-ratio: 4 / 3;
  object-fit: cover;
}

@media (max-width: 767px) {
  .about_img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .about__item:nth-child(2) .about_img {
    order: 2;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .about__item:nth-child(3) .about_img {
    display: none;
  }
}

@media (min-width: 1200px) {
  .about_img {
    width: 40%;
    flex: 0 0 40%;
  }
}

.about__text {
  padding: var(--gap);
  background-color: var(--color--white);
}

.about__sublist {
  --gap: 8px;
  gap: var(--gap);
  margin-top: 16px;
}

.about__subitem {
  --gap: 12px;
  gap: var(--gap);
  display: flex;
}

.about__subitem .list-maker {
  height: 12px;
  flex: 0 0 12px;
}

.about__subitem .list-maker--number {
  text-align: center;
  font-weight: 600;
}

.steps {
  background-color: var(--color--white);
}

.steps__item {
  flex-grow: 0;
  flex-shrink: 0;
  gap: 16px;
  display: flex;
}

@media (min-width: 768px) {
  .steps__item {
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .steps__item {
    --items: 4;
  }
}

.step__number {
  width: 40px;
  height: 50px;
  text-align: center;
  flex: 0 0 40px;
  font-size: 50px;
  font-weight: 800;
  line-height: .8;
}

.step__title {
  margin-bottom: 8px;
  font-weight: 600;
}

.customers.section {
  background-color: var(--color--green);
}

.hero .customers {
  width: 100%;
  margin-top: auto;
}

.hero .customers__title {
  margin-top: 60px;
  margin-bottom: 16px;
  font-size: 16px;
}

section.customers .customers__item {
  height: 80px;
  fill: currentColor;
  color: var(--color--black);
  background-color: #fff;
  background-color: var(--color--white);
  box-shadow: 0 4px 24px #0000001a;
  box-shadow: var(--box-shadow);
  --items: 2;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 20px;
  transition: all .3s linear;
  position: relative;
}

section.customers .customers__item:hover {
  transform: scale(1.05);
}

@media (min-width: 768px) {
  section.customers .customers__item {
    --items: 4;
  }
}

@media (min-width: 1200px) {
  section.customers .customers__item {
    --items: 5;
  }
}

.customers__swiper .customers__item {
  height: 40px;
  padding: 10px 20px;
}

.customer__icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.customers__swiper {
  background-color: var(--color--white);
}

.swiper-free-mode > .swiper-wrapper {
  transition-timing-function: linear !important;
}

.partners__list {
  justify-content: flex-start;
  margin-top: 20px;
}

.partners__item {
  height: 80px;
  background-color: var(--color--white);
  box-shadow: var(--box-shadow);
  --items: 2;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 20px;
  transition: all .3s linear;
}

.partners__item:hover {
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .partners__item {
    --items: 4;
  }
}

@media (min-width: 1200px) {
  .partners__item {
    --items: 5;
  }
}

.partners__icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.contacts {
  padding-top: calc(var(--header-h)  + var(--section-p-top));
  background: linear-gradient(to bottom, var(--color--white) var(--header-h), var(--color--black) var(--header-h) 100%);
}

@media (max-width: 767px) {
  .contacts {
    padding-top: calc(var(--header-h)  + var(--section-p-top) / 2);
  }
}

.contacts .container {
  --gap: 32px;
  column-gap: var(--gap);
  flex-wrap: wrap;
  display: flex;
}

@media (max-width: 767px) {
  .contacts .container {
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .contacts .container {
    --gap: 64px;
  }
}

.contacts__title {
  color: var(--color--white);
  flex: 0 0 100%;
}

.contacts__box {
  flex: 0 0 calc((100% - var(--gap)) / 2);
  background-color: var(--color--white);
  padding: 32px;
}

.contacts__box--1 {
  gap: 32px;
}

@media (max-width: 767px) {
  .contacts__box--1 {
    padding-bottom: 0;
  }
}

.contact {
  color: var(--color--white);
  font-size: 24px;
  font-weight: 700;
}

#contactForm, #contactForm label {
  gap: 8px;
}

#contactForm {
  gap: 16px;
  margin-top: 20px;
}

#contactForm input, #contactForm textarea {
  width: 100%;
  height: 40px;
  padding: 4px 12px;
}

#contactForm textarea {
  height: 80px;
  resize: none;
  padding: 12px;
}

.contact__title {
  margin-bottom: 8px;
}

.contact__link:focus-visible {
  outline: none;
}

.contact__link--tel {
  font-size: 20px;
  font-weight: 700;
}

.work-schedule {
  margin-top: 8px;
}

/*# sourceMappingURL=index.6daf99b0.css.map */
