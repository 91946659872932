.logo {
  width: 80px;
  max-height: 60px;

  font-family: var(--font--title);
  font-size: 32px;
  font-weight: 600;

  transition: all 300ms linear;

  @media screen and (min-width: 768px) {
    font-size: 40px;
    font-weight: 600;
  }
}
.logo:hover, .logo:focus {.logo__icon {transform: scale(1.02);}}

.logo__icon {
  width: 80px;
  height: 40px;
}
.logo__text {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  max-height: 20px;
}

// ===== modify =====
.header__logo {
  height: 40;
  margin-right: auto;

  color: var(--color--black);

  .logo__icon{height: 28px;}
  .logo__text {height: 12px; font-size: 12px;}
}
.modal-form__logo {
  color: var(--color--green);

}
.contacts__logo {
  display: block;
  margin-top: auto;

  color: var(--color--white);
}