.services {
  padding-top: calc(var(--header-h) + var(--section-p-top));
  padding-bottom: 0;  

  background: linear-gradient(
    to bottom,
    // var(--color--green) var(--header-h),
    var(--color--white) var(--header-h),
    var(--color--white) var(--header-h) 100%,
  );

  @include breakpoint-max($medium - 1) {
    padding-top: calc(var(--header-h) + var(--section-p-top) / 2);
  }
}
.services__tabs {
  display: flex;
  // gap: 16px;

  // border: 1px solid;
  // border-color: var(--color--green);
  // background-color: #fff;
  // border-bottom: none;

  @include breakpoint-max($medium - 1) {
    flex-direction: column;
  }
}
.services__tabs-content {
  @extend %flex-dir-column;
  gap: 20px;
}

.tabs-content__list, 
.services__prices {
  padding: 16px;
  background-color: #fff;
}
.tabs-content__list {
  padding-top: 0;
}

.price__wrap {
  padding: 16px;
}

.services__tabs-nav {
  background-color: var(--color--white);

  @include breakpoint-min($medium) {
    flex: 0 0 240px;
  }
}

.services__tabs-nav .tabs-nav__btn {
  width: 100%;
  height: 60px;
  padding: 0px 16px;

  text-align: start;

  // background-color: #fff;
  background-color: var(--color--white);
  // background: linear-gradient(90deg, transparent 98%, var(--color--green) 10%);

  &.current {
    color: whitesmoke;
    background-color: var(--color--green);
  }
}
.tabs-content__item.isHidden {
  display: none;
  pointer-events: none;
}

.service__title {
  height: 60px;
  padding: 16px 0;
  // margin-bottom: 20px;

  font-size: 28px;
  font-weight: 700;

  border-bottom: 1px solid var(--color--white);
}
.service__desc:not(:last-child) {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color--white);
}
.service__desc {
  @extend %flex-dir-column;
  
  --gap: 12px;
  gap: var(--gap);

  padding-top: 16px;
}
.service__text {
  @extend %flex-dir-column;
  gap: var(--gap);

  .service-desc__extra-content-inner & {
    margin-top: var(--gap);
  }
}
.service__text span {
  font-weight: 600;
}

.service-desc__extra-content {
  display: grid;
  grid-template-rows: 1fr;
  // max-height: calc(100% + 100px);

  // overflow: hidden;
  // transition: max-height 500ms linear;
  transition: grid-template-rows 300ms linear;

  &.isHidden {
    // max-height: 0;
    grid-template-rows: 0fr;
  }
}

.service-desc__extra-content-inner {
  overflow: hidden;
}

.service-desc__list {
  @extend %flex-dir-column;
  gap: var(--gap);
}


.service-desc__item {
  display: flex;
  --gap:12px;
  gap: var(--gap);
}
.service-desc__item .list-maker {
//  .list-maker {
  flex: 0 0 20px;
  height: 20px;
}
.service-desc__item .list-maker--number {
  text-align: center;
  font-weight: 600;
}
.service-desc__item .list-maker--square {
// .list-maker--square {
  position: relative;

  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    width: 8px;
    height: 8px;  

    border-radius: 50%;
    background-color: var(--color--black);
    transform: translate(-50%, -50%);
  }
}

.service-desc__btn {
  background-color: var(--color--white);
}


.services__tabs-content .prices__title {
  margin-bottom: 20px;

  font-size: 28px;
  font-weight: 700;
}

.services__tabs-content .prices__list {
  justify-content: start;
}

.prices__box.isHidden {
  display: none;
  pointer-events: none;
}

.price--consulting,
.price--school {
 --items: 2;
}

.price--consulting .price__value,
.price--school .price__value {
  margin-top: auto;

  @include breakpoint-max($large - 1) {
    display: flex;
    flex-direction: column;
  }
}

