.steps {

  background-color: var(--color--white);
}
.steps__list {
  @extend %cardSet;
}
.steps__item {
  @extend %cardSet__item;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  gap: 16px;

  @include breakpoint-min($medium) {
    flex-direction: column;
  }
  @include breakpoint-min($large) {
    --items: 4;
  }
}
.step__number {
  flex: 0 0 40px;
  width: 40px;
  height: 50px;

  font-size: 50px;
  font-weight: 800;
  line-height: 0.8;
  text-align: center;
}
.step__title {
  font-weight: 600;
  margin-bottom: 8px;
}