footer {
  @extend %flex-center-a;
  flex-direction: column;
  margin-top: auto;
  // padding-top: 32px;

  color: var(--color--white);
  background-color: var(--color--black);
}

.footer__top,
.footer__bottom {
  width: 100%;
  // height: 40px;
  padding: 16px 0;
}

.footer__bottom .container {
  @extend %flex-center-a;
  justify-content: space-between;

  @include breakpoint-max($medium - 1) {
    flex-direction: column;
    gap: 12px;
  }
}


.copyright,
.developer {
  font-size: 12px;
  text-transform: uppercase;

  color: var(--color--white);
}

.developer__link {
  font-weight: 700;
}